body {
  background: #fafafa;
  font-family: Roboto,RobotoDraft,Helvetica,Arial,sans-serif;
  color: #333; }

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important; }

/* ==========================================================================
Developement Ribbon
========================================================================== */
.ribbon {
  background-color: #a00;
  box-shadow: 0 0 10px #888;
  left: -3.5em;
  moz-box-shadow: 0 0 10px #888;
  moz-transform: rotate(-45deg);
  ms-transform: rotate(-45deg);
  o-transform: rotate(-45deg);
  overflow: hidden;
  position: absolute;
  top: 40px;
  transform: rotate(-45deg);
  webkit-box-shadow: 0 0 10px #888;
  webkit-transform: rotate(-45deg);
  white-space: nowrap;
  width: 15em;
  z-index: 9999;
  pointer-events: none; }
  .ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none; }

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
  font-size: 10px;
  color: #ccc; }

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand.logo {
  padding: 7px 15px; }
  .navbar-brand.logo .logo-img {
    height: 40px;
    display: inline-block; }

/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
  margin: 50px auto 0 auto;
  width: 300px;
  font-size: 18px;
  font-weight: 200;
  line-height: 30px;
  background-color: #eee;
  border-radius: 6px;
  padding: 60px; }
  .hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px; }

.hipster {
  display: inline-block;
  width: 347px;
  height: 497px;
  background: url("../images/hipster.png") no-repeat center top;
  background-size: contain; }

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .hipster {
    background: url("../images/hipster2x.png") no-repeat center top;
    background-size: contain; } }

/* ==========================================================================
Generic styles
========================================================================== */
.error {
  color: white;
  background-color: red; }

.pad {
  padding: 10px; }

.break {
  white-space: normal;
  word-break: break-all; }

.voffset {
  margin-top: 2px; }

.voffset1 {
  margin-top: 5px; }

.voffset2 {
  margin-top: 10px; }

.voffset3 {
  margin-top: 15px; }

.voffset4 {
  margin-top: 30px; }

.voffset5 {
  margin-top: 40px; }

.voffset6 {
  margin-top: 60px; }

.voffset7 {
  margin-top: 80px; }

.voffset8 {
  margin-top: 100px; }

.voffset9 {
  margin-top: 150px; }

.readonly {
  background-color: #eee;
  opacity: 1; }

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover {
  cursor: pointer; }

.hand {
  cursor: pointer; }

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover, #healthCheck .popover {
  top: inherit;
  display: block;
  font-size: 10px;
  max-width: 1024px; }

#healthCheck .popover {
  margin-left: -50px; }

.health-details {
  min-width: 400px; }

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strength {
  display: inline;
  list-style: none;
  margin: 0;
  margin-left: 15px;
  padding: 0;
  vertical-align: 2px; }

.point {
  background: #DDD;
  border-radius: 2px;
  display: inline-block;
  height: 5px;
  margin-right: 1px;
  width: 20px; }
  .point:last {
    margin: 0 !important; }

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alertcontainer {
  border-radius: 4px; }

.alerts .alert {
  margin-bottom: 0px !important;
  text-overflow: ellipsis; }
  .alerts .alert pre {
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0; }
  .alerts .alert .popover pre {
    font-size: 10px; }

.alerts .toast {
  position: fixed;
  height: auto !important;
  box-shadow: 8px 8px 3px #888888;
  margin-bottom: 0px;
  z-index: 9999;
  width: 70%; }
  .alerts .toast.left {
    left: 5px; }
  .alerts .toast.right {
    right: 5px; }
  .alerts .toast.bottom {
    bottom: 55px; }

@media screen and (min-width: 800px) {
  .alerts .toast {
    width: 30%;
    height: auto !important;
    box-shadow: 5px 5px 3px #888888; } }

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left {
  padding-left: 0 !important; }

.no-padding-right {
  padding-right: 0 !important; }

.no-padding-top {
  padding-top: 0 !important; }

.no-padding-bottom {
  padding-bottom: 0 !important; }

.no-padding {
  padding: 0 !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min {
  width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
   http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-end;
  justify-content: flex-end; }

.jh-table > tbody > tr > td {
  /* Align text in td verifically (top aligned by Bootstrap) */
  vertical-align: middle; }

.jh-table > thead > tr > th > .glyphicon-sort, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
  /* less visible sorting icons */
  opacity: 0.5; }
  .jh-table > thead > tr > th > .glyphicon-sort:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer; }

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
  margin-bottom: 15px; }

@media screen and (min-width: 768px) {
  .dl-horizontal.jh-entity-details > dt {
    margin-bottom: 15px; }
  .dl-horizontal.jh-entity-details > dd {
    border-bottom: 1px solid #eee;
    padding-left: 180px;
    margin-left: 0; } }

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav, .pagination, .carousel, .panel-title a {
  cursor: pointer; }

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-default {
  border: 0; }

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup > li > div.uib-datepicker > table:focus {
  outline: none; }

/* jhipster-needle-scss-add-main JHipster will add new css style */
;
/** custom WAMA */
html, body {
  height: 100%; }

#wrap-pos {
  margin: 0 auto;
  height: 100%; }

#wrap {
  margin: 0 auto;
  position: relative; }

ul {
  padding: 0px; }

.md-datepicker-calendar-pane {
  z-index: 1200 !important; }

/** NAVBAR **/
.custom-navbar-brand img {
  display: inline-block !important; }

.navbar-logo {
  max-width: 35px;
  margin-top: -6px; }

/** POS **/
.pos {
  padding-top: 15px;
  color: #565d63; }

.maxheight {
  height: 100%; }

.cash {
  margin-top: -80px;
  padding-top: 60px; }

.cash-sidebar {
  position: relative;
  background-color: #ffffff;
  padding: 10px 0px;
  border-radius: 6px;
  height: calc(100% - 80px); }

.cash-categories-container {
  height: 100%; }

.pos-central {
  height: calc(100% - 50px); }

.cash-categories {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden; }

.pos-category {
  padding: 0px;
  outline: none; }

.pos-category-image {
  height: 60px;
  width: 60px; }

.pos-category-name {
  height: 60px; }

.pos-category-number {
  font-size: 10pt;
  display: inline-block;
  color: #cccccc; }

.pos-category:hover {
  background-color: #0299E0;
  font-weight: bold;
  cursor: pointer;
  color: #ffffff; }

.pos-category-active {
  background-color: #0299E0;
  font-weight: bold;
  cursor: pointer;
  color: #ffffff;
  border-radius: 6px; }

.pos-keypad {
  background-color: #fafbfc;
  z-index: 10;
  border-radius: 10px;
  position: absolute;
  bottom: 15%;
  left: 5%;
  padding: 50px;
  text-align: center;
  -webkit-box-shadow: 5px 10px 60px -24px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 10px 60px -24px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 10px 60px -24px rgba(0, 0, 0, 0.75); }
  .pos-keypad .keypad-amount {
    font-size: 40pt;
    font-weight: 700;
    color: #212933;
    text-align: center;
    padding: 15px;
    margin-bottom: 20px; }
  .pos-keypad .keypad-buttons {
    display: flex;
    flex-wrap: wrap;
    width: 410px; }
    .pos-keypad .keypad-buttons .keypad-btn {
      font-size: 18pt;
      color: #212933;
      flex: 1 130px;
      margin: 2px;
      height: 70px;
      line-height: 70px;
      text-align: center;
      vertical-align: middle;
      -webkit-user-select: none;
      /* text not selectable Safari */
      -moz-user-select: none;
      /* Firefox */
      -ms-user-select: none;
      /* IE10+/Edge */
      user-select: none;
      /* Standard */ }
    .pos-keypad .keypad-buttons .keypad-btn:hover {
      cursor: pointer;
      background-color: #e3e3e3; }
    .pos-keypad .keypad-buttons .keypad-btn-border {
      background-color: #ffffff;
      border-radius: 6px;
      border: 1px solid #d9d9d9; }
  .pos-keypad .keypad-add-btn {
    background-color: #0299E0;
    color: #ffffff;
    width: 100%;
    font-size: 18pt;
    margin-top: 30px; }
  .pos-keypad .currency-tax-button {
    border: 1px solid #2fbc1f;
    color: #2fbc1f;
    border-radius: 5px;
    display: inline-block;
    padding: 3px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px; }
  .pos-keypad .currency-tax-button:hover {
    cursor: pointer; }

.close {
  position: absolute;
  right: 20px;
  top: 20px; }

.col-same-height {
  display: flex !important;
  display: -webkit-flex;
  flex-wrap: wrap; }

.currency-tax-picker {
  background-color: #fafbfc;
  border-radius: 10px;
  z-index: 20;
  position: absolute;
  bottom: 30%;
  left: 20%;
  padding: 50px;
  text-align: center;
  -webkit-box-shadow: 5px 10px 60px -24px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 10px 60px -24px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 10px 60px -24px rgba(0, 0, 0, 0.75); }
  .currency-tax-picker .select-button {
    border-radius: 5px;
    font-size: 18pt;
    margin: 20px;
    padding: 20px; }
  .currency-tax-picker .select-btn-normal {
    border: 1px solid #2fbc1f;
    color: #2fbc1f; }
  .currency-tax-picker .select-btn-selected {
    background-color: #2fbc1f;
    color: #ffffff; }
  .currency-tax-picker .tax-button:hover {
    cursor: pointer; }
  .currency-tax-picker .inline {
    display: inline-block; }

.cash .total {
  background-color: transparent;
  border: 1px solid #dddddd;
  position: relative;
  font-weight: bold; }

.cash-right-side {
  position: relative;
  background-color: #ffffff;
  padding-right: 0px;
  padding-top: 10px;
  padding-left: 0px;
  border-radius: 6px;
  height: calc(100% - 80px); }

.cash-current-sale a {
  color: #565d63; }

.cash-current-sale a:hover, .cash-current-sale a:active, .cash-entry:hover {
  color: #474a50;
  background-color: #eeeeee; }

.pos-no-products {
  font-size: 18pt;
  padding: 20px; }

.cash-current-sale-container {
  height: calc(100% - 80px); }

.cash-current-sale {
  height: 100%;
  padding-bottom: 30px;
  overflow-y: auto;
  overflow-x: hidden; }

.cash-entry {
  font-size: 1.3em;
  padding: 10px; }

.cash-entry .price {
  font-weight: bold; }

.cash .btn-image {
  max-height: 100px;
  max-width: 100px; }

.cash .img-container {
  width: 100px;
  display: inline-block;
  vertical-align: top;
  text-align: center; }

.charge-button {
  background-color: #0299E0;
  height: 70px;
  color: #ffffff;
  font-size: 20pt;
  text-align: center;
  vertical-align: middle;
  position: absolute;
  bottom: 0;
  width: 100%;
  line-height: 70px;
  border-radius: 0 0 6px 6px;
  box-shadow: none;
  border: 0; }

.pos-toolbar {
  height: 60px;
  line-height: 60px;
  color: #ffffff;
  font-size: 16pt;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  vertical-align: middle;
  background-color: #0299E0; }

.pos-toolbar a {
  color: #ffffff;
  display: block;
  height: 100%;
  width: 100%; }

.pos-toolbar a:hover {
  height: 100%;
  background-color: #0273ab;
  color: #ffffff;
  text-decoration: none; }

.pos-toolbar a:active {
  text-decoration: none; }

.pos-toolbar-input-wrapper {
  position: relative;
  height: 100%; }

.pos-toolbar-search-input-icon {
  position: absolute;
  top: 2px;
  right: 22%;
  font-size: 0.8em;
  z-index: 5;
  color: #aaaaaa; }

.pos-toolbar-switch-icon {
  position: relative;
  top: 2px;
  left: 5px;
  z-index: 5;
  color: #FFFFFF; }

.pos-toolbar-switch-icon:hover {
  transition: 500ms ease-out;
  cursor: pointer; }

.pos-toolbar-switch-icon:focus {
  outline: none; }

.pos-toolbar-input-wrapper input {
  display: inline-block;
  width: 70%; }

.pos-quantity-input {
  color: #565d63;
  width: 90px;
  display: inline-block;
  height: 55px;
  text-align: center;
  font-size: 14pt;
  margin-left: 5px;
  margin-right: 5px; }

.pos-quantity-btn {
  color: #565d63;
  font-size: 22pt;
  width: 55px; }

.pos-pagination-btns {
  position: absolute;
  right: 10px;
  bottom: 28px; }

.vcenter {
  display: flex;
  align-items: center; }

.keypad {
  width: 100%;
  position: sticky;
  position: -webkit-sticky;
  height: 200px;
  left: 0;
  bottom: 0;
  background-color: #eeeeee; }

.keypad .btn {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 4px; }

.cash-left-side {
  overflow: auto; }

.row-vspacer {
  margin-top: 5px; }

.pos-item {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 15px;
  position: relative; }

.pos-item-quantity {
  position: absolute;
  right: 10px;
  bottom: 5px;
  font-size: 10pt;
  color: #999999;
  font-weight: 800; }

.pos-item a {
  width: 247px;
  height: 120px; }

.pos-item-text {
  display: inline-block;
  width: 120px;
  height: 90px;
  font-size: 12pt;
  vertical-align: middle;
  overflow: hidden;
  white-space: normal;
  /* Wrap lines of text */
  text-overflow: ellipsis;
  /* Ellipses (cross-browser) */
  -o-text-overflow: ellipsis; }

.pos-item-price {
  position: absolute;
  bottom: 5px;
  left: 117px;
  font-weight: 600;
  color: #555555; }

.pos-item-broken {
  background-color: #fbe8da; }

.truncate {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.pos-edit-order {
  font-weight: bold;
  color: #ffffff;
  background-color: #f0ad4e;
  padding: 5px;
  text-align: center;
  border-radius: 6px 6px 0 0;
  font-size: 14pt;
  margin-top: -10px; }

.pos-save-btn {
  width: 100%;
  font-size: 16pt;
  height: 40px;
  margin-top: 25px;
  margin-left: 0px !important; }

/** WHAREHOUSE **/
.thumbnail-product-photo {
  max-width: 60px;
  max-height: 100% !important;
  transition: all .2s ease-in-out;
  margin: 0px; }

.thumbnail-container {
  padding: 3px;
  width: 66px;
  height: 66px;
  background-color: #ffffff;
  margin: 0px; }

.thumbnail-employee-container {
  padding: 3px;
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  margin: 0px; }

.thumbnail-big {
  max-width: 80px;
  max-height: 80px; }

.productStockEntriesThumbnail {
  max-width: 180px;
  max-height: 180px;
  padding: 0px;
  border: 1px solid #aaa; }

.currencyPrice {
  display: inline;
  width: auto; }

.margin-top-0 {
  margin-top: 0px; }

.margin-top-10 {
  margin-top: 10px; }

.margin-top-20 {
  margin-top: 20px; }

.margin-top-30 {
  margin-top: 30px; }

.margin-top-40 {
  margin-top: 40px; }

.margin-bottom-10 {
  margin-bottom: 10px; }

.margin-bottom-60 {
  margin-bottom: 60px; }

.margin-bottom-15 {
  margin-bottom: 15px; }

.uploadPhoto {
  width: 200px;
  height: 150px;
  margin-bottom: 10px; }

.uploadPhoto > img {
  max-width: 100%;
  height: 100%; }

.stats-box {
  background-color: #ffffff;
  padding: 10px; }

.stats-box-title {
  background-color: #0299E0;
  text-transform: uppercase;
  color: #ffffff;
  display: inline-block;
  clear: both;
  top: -14px;
  position: relative;
  font-size: 8pt;
  max-height: 16px;
  font-weight: bold;
  padding: 0px 4px; }

.stats-box li {
  list-style: none;
  margin-bottom: 10px; }

.stats-box li span {
  list-style: none;
  display: inline-block; }

.stats-box-value {
  font-size: 13pt;
  color: #666666;
  font-weight: normal;
  margin-left: 10px; }

.stock-change-input {
  width: 65px; }

.loadByBarcodeQuantity {
  width: 120px !important;
  resize: horizontal;
  font-size: 24px;
  height: 60px; }

.loadByBarcodeCode {
  width: 280px !important;
  height: 60px !important;
  font-size: 24px; }

.settingsSection {
  margin-top: 40px; }

.noElementsFoundBox {
  font-weight: bold;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 40px;
  font-size: 13pt; }

.date-picker-box {
  position: absolute;
  width: 500px;
  right: 25px;
  margin-top: 2px;
  z-index: 200;
  background-color: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 8px; }

.date-picker-selector {
  background-color: #f4f4f4;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 8px;
  font-weight: bold;
  font-size: 13pt; }

.date-picker-selector-box {
  display: table;
  position: relative; }

.date-picker-selector-box a {
  color: #333333;
  text-decoration: none; }

.date-picker-selector-box a:hover {
  color: #333333;
  text-decoration: none; }

.date-picker-selector-box a:active {
  color: #333333;
  text-decoration: none; }

.date-picker-selector-box a:visited {
  color: #333333;
  text-decoration: none; }

.columns-selector {
  background-color: #f4f4f4;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 8px;
  font-size: 12pt; }

.quantityBox {
  background-color: #f4f4f4;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 4px;
  display: inline-flex;
  margin-right: 4px;
  margin-left: 4px;
  font-size: 14pt;
  text-align: center;
  min-width: 150px;
  justify-content: space-between; }
  .quantityBox .qty-text {
    margin-left: 2px;
    margin-right: 5px; }

.dashboard-box {
  border: 1px solid #dddddd;
  padding: 10px;
  background-color: #ffffff;
  margin-bottom: 15px; }

.stats-main-box {
  padding: 10px;
  color: #a7a7a7;
  background-color: #f8f8f8; }

.stats-main-box-logo {
  text-align: center; }

.stats-main-box-name {
  font-size: 9pt;
  font-weight: bold;
  text-transform: uppercase; }

.ellipsis {
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap; }

.stats-main-box-value {
  font-size: 20pt;
  color: #666666;
  font-weight: bold; }

.row.stats-main-box-content {
  overflow: hidden; }

.row.stats-main-box-content > div[class^="col-"] {
  text-align: center;
  padding-bottom: 100px;
  margin-bottom: -100px;
  border-left: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea; }

.row.stats-main-box-content div[class^="col-"]:first-child {
  border-left: none; }

.row.stats-main-box-content div[class^="col-"]:last-child {
  border-right: none; }

.stats-second-box {
  margin-left: 0px;
  padding-left: 0px;
  padding-right: 4px;
  margin-right: 0px; }

.stats-second-box-value {
  font-size: 15pt;
  color: #666666;
  font-weight: bold; }

.thumb {
  width: 100%;
  max-width: 115px;
  max-height: 100px;
  position: relative; }

.progressBar {
  display: inline-block;
  width: 100%;
  line-height: 8px;
  border: 0px solid #CCC;
  margin-bottom: 4px; }

.progressBar div {
  font-size: x-small;
  background: #0198EF;
  opacity: 0.7;
  width: 0;
  color: #EEEEEE;
  padding: 2px; }

.selectImage {
  width: 100%; }

.user {
  display: inline-block;
  width: 36px;
  height: 36px;
  vertical-align: middle;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; }

.gliphNavbar {
  line-height: 36px; }

.btn-wama-normal {
  color: #555;
  background-color: #eee;
  border-color: #eee; }
  .btn-wama-normal:focus, .btn-wama-normal.focus {
    color: #555;
    background-color: #d5d5d5;
    border-color: #aeaeae; }
  .btn-wama-normal:hover {
    color: #555;
    background-color: #d5d5d5;
    border-color: #cfcfcf; }
  .btn-wama-normal:active, .btn-wama-normal.active,
  .open > .btn-wama-normal.dropdown-toggle {
    color: #555;
    background-color: #d5d5d5;
    border-color: #cfcfcf; }
    .btn-wama-normal:active:hover, .btn-wama-normal:active:focus, .btn-wama-normal:active.focus, .btn-wama-normal.active:hover, .btn-wama-normal.active:focus, .btn-wama-normal.active.focus,
    .open > .btn-wama-normal.dropdown-toggle:hover,
    .open > .btn-wama-normal.dropdown-toggle:focus,
    .open > .btn-wama-normal.dropdown-toggle.focus {
      color: #555;
      background-color: #c3c3c3;
      border-color: #aeaeae; }
  .btn-wama-normal:active, .btn-wama-normal.active,
  .open > .btn-wama-normal.dropdown-toggle {
    background-image: none; }
  .btn-wama-normal.disabled:hover, .btn-wama-normal.disabled:focus, .btn-wama-normal.disabled.focus, .btn-wama-normal[disabled]:hover, .btn-wama-normal[disabled]:focus, .btn-wama-normal[disabled].focus,
  fieldset[disabled] .btn-wama-normal:hover,
  fieldset[disabled] .btn-wama-normal:focus,
  fieldset[disabled] .btn-wama-normal.focus {
    background-color: #eee;
    border-color: #eee; }
  .btn-wama-normal .badge {
    color: #eee;
    background-color: #555; }

.btn-wama-dark {
  color: #555422;
  background-color: #cecece;
  border-color: #cecece; }
  .btn-wama-dark:focus, .btn-wama-dark.focus {
    color: #555422;
    background-color: #b5b5b5;
    border-color: #8e8e8e; }
  .btn-wama-dark:hover {
    color: #555422;
    background-color: #b5b5b5;
    border-color: #afafaf; }
  .btn-wama-dark:active, .btn-wama-dark.active,
  .open > .btn-wama-dark.dropdown-toggle {
    color: #555422;
    background-color: #b5b5b5;
    border-color: #afafaf; }
    .btn-wama-dark:active:hover, .btn-wama-dark:active:focus, .btn-wama-dark:active.focus, .btn-wama-dark.active:hover, .btn-wama-dark.active:focus, .btn-wama-dark.active.focus,
    .open > .btn-wama-dark.dropdown-toggle:hover,
    .open > .btn-wama-dark.dropdown-toggle:focus,
    .open > .btn-wama-dark.dropdown-toggle.focus {
      color: #555422;
      background-color: #a3a3a3;
      border-color: #8e8e8e; }
  .btn-wama-dark:active, .btn-wama-dark.active,
  .open > .btn-wama-dark.dropdown-toggle {
    background-image: none; }
  .btn-wama-dark.disabled:hover, .btn-wama-dark.disabled:focus, .btn-wama-dark.disabled.focus, .btn-wama-dark[disabled]:hover, .btn-wama-dark[disabled]:focus, .btn-wama-dark[disabled].focus,
  fieldset[disabled] .btn-wama-dark:hover,
  fieldset[disabled] .btn-wama-dark:focus,
  fieldset[disabled] .btn-wama-dark.focus {
    background-color: #cecece;
    border-color: #cecece; }
  .btn-wama-dark .badge {
    color: #cecece;
    background-color: #555422; }

.btn-wama-primary {
  color: white;
  background-color: #0299E0;
  border-color: #0299E0; }
  .btn-wama-primary:focus, .btn-wama-primary.focus {
    color: white;
    background-color: #0276ad;
    border-color: #014362; }
  .btn-wama-primary:hover {
    color: white;
    background-color: #0276ad;
    border-color: #0170a3; }
  .btn-wama-primary:active, .btn-wama-primary.active,
  .open > .btn-wama-primary.dropdown-toggle {
    color: white;
    background-color: #0276ad;
    border-color: #0170a3; }
    .btn-wama-primary:active:hover, .btn-wama-primary:active:focus, .btn-wama-primary:active.focus, .btn-wama-primary.active:hover, .btn-wama-primary.active:focus, .btn-wama-primary.active.focus,
    .open > .btn-wama-primary.dropdown-toggle:hover,
    .open > .btn-wama-primary.dropdown-toggle:focus,
    .open > .btn-wama-primary.dropdown-toggle.focus {
      color: white;
      background-color: #015e8a;
      border-color: #014362; }
  .btn-wama-primary:active, .btn-wama-primary.active,
  .open > .btn-wama-primary.dropdown-toggle {
    background-image: none; }
  .btn-wama-primary.disabled:hover, .btn-wama-primary.disabled:focus, .btn-wama-primary.disabled.focus, .btn-wama-primary[disabled]:hover, .btn-wama-primary[disabled]:focus, .btn-wama-primary[disabled].focus,
  fieldset[disabled] .btn-wama-primary:hover,
  fieldset[disabled] .btn-wama-primary:focus,
  fieldset[disabled] .btn-wama-primary.focus {
    background-color: #0299E0;
    border-color: #0299E0; }
  .btn-wama-primary .badge {
    color: #0299E0;
    background-color: white; }

.btn-no-background {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  border: 0;
  color: #555555;
  background: transparent; }

.pagination .active a, .pagination .active a:hover, .pagination .active a:focus {
  background-color: #0299E0;
  border-color: #0299E0; }

.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
  color: #fff;
  background-color: #0299E0; }

a, a:hover, a:focus {
  color: #0299E0; }

.text-wama-primary {
  color: #555; }

.text-wama-secondary {
  color: #888; }

.icon-normal-color {
  color: #555555; }

.small-vertical-spacer {
  line-height: 20px;
  height: 20px; }

.navbar-default {
  background-color: #0299E0; }

.navbar-default .navbar-nav > li > a, .navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
  color: white; }

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
  color: white;
  background-color: #0299E0;
  border-bottom: 4px solid white; }

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
  background-color: #0299E0;
  color: white; }

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
  background-color: #0299E0; }

.footer {
  margin-top: 150px;
  background-color: #EEE;
  padding-top: 40px;
  padding-bottom: 40px;
  color: gray;
  font-size: 0.9em; }

.navbar-default .navbar-toggle {
  border-color: white; }

.navbar-default .navbar-toggle .icon-bar {
  background-color: white; }

.metadata {
  display: block;
  margin-top: 5px; }

.login {
  max-width: 400px;
  padding: 40px;
  margin-top: 80px;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid #c8d1d3;
  margin-bottom: 80px; }

.login input {
  border: 0; }

.login .input-group-addon {
  border: 0;
  border-radius: 0px;
  background-color: #ffffff;
  border-right: 1px solid #c8d1d3;
  font-size: 1em; }

.login .input-group {
  border-radius: 2px;
  -moz-border-radius: 0px;
  border: 1px solid #c8d1d3; }

.login .form-control {
  border: 0px !important;
  border-radius: 0px !important;
  -moz-border-radius: 0px !important; }

.login button {
  border-radius: 2px !important;
  width: 100%;
  height: 40px; }

.login .title {
  background-color: #FFF;
  position: relative;
  z-index: 2;
  width: 50px;
  margin: 0 auto; }

.login .title :after {
  content: '';
  position: absolute;
  width: 50%;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1;
  border-bottom: 1px solid #8d9293; }

.form-line {
  width: 100%;
  position: relative;
  text-align: center;
  font-size: 0.9em;
  z-index: 2;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #8d9293; }

.forgot-password-link {
  margin-top: 40px; }

.logo-login {
  margin-bottom: 35px; }

.register {
  margin-top: 80px; }

.register-info {
  width: 300px;
  background-color: #0299E0; }

.navbar-nav > li > a {
  padding-top: 7px;
  padding-bottom: 7px; }

.axis path,
.axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges; }

.x.axis path {
  display: none; }

.line {
  fill: none;
  stroke: steelblue;
  stroke-width: 1.5px; }

.flag {
  height: 25px; }

html, body {
  height: 100%; }

#wrap {
  min-height: 100%; }

#main {
  overflow: auto;
  padding-bottom: 180px; }

/* must be same height as the footer */
#footer {
  position: relative;
  margin-top: -180px;
  /* negative value of footer height */
  height: 180px;
  clear: both; }

/*Opera Fix*/
body:before {
  /* thanks to Maleika (Kohoutec)*/
  content: "";
  height: 100%;
  float: left;
  width: 0;
  margin-top: -32767px;
  /* thank you Erik J - negate effect of float*/ }

.social a {
  color: #70726F;
  font-size: 20px;
  padding: 10px;
  -webkit-transition: all .5s ease;
  transition: all .5s ease; }

.social a:hover {
  color: #0299E0;
  -webkit-transition: all .5s ease;
  transition: all .5s ease; }

.footer a {
  color: #AAAAAA; }

.modalImage .image-wrapper {
  text-align: center; }

.modalImage .image-wrapper img {
  max-width: 560px;
  max-height: 560px; }

.modalImage .image-description {
  text-align: center;
  margin-top: 10px; }

.footer-links {
  text-align: center; }

@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #ffffff; } }

@media (max-width: 989px) {
  .footer-links {
    text-align: left; } }

.value-description {
  border: 1px solid #BBBBBB;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  margin: 0px 0px 5px; }

.search-results label > input {
  /* HIDE RADIO */
  visibility: hidden;
  /* Makes input not-clickable */
  position: absolute;
  /* Remove input from document flow */ }

.search-results label > input + img {
  /* IMAGE STYLES */
  cursor: pointer;
  border: 2px solid transparent; }

.search-results label > input:checked + img {
  /* (RADIO CHECKED) IMAGE STYLES */
  border: 2px solid #0299E0; }

.search-results label > input + div {
  /* IMAGE STYLES */
  cursor: pointer;
  border: 2px solid transparent; }

.search-results label > input:checked + div {
  /* (RADIO CHECKED) IMAGE STYLES */
  border: 2px solid #0299E0; }

.margin-right-10 {
  margin-right: 10px; }

.barcode-lookup-box {
  margin-top: 10px;
  background-color: rgba(238, 235, 243, 0.76);
  border: 1px solid rgba(209, 206, 214, 0.75);
  padding: 0px 10px 10px; }

.margin-top-5 {
  margin-top: 5px; }

.highlight-change {
  color: #2fbc1f; }

.alertText {
  color: #9f191f; }

thead {
  background-color: #ffffff;
  color: #5c656f; }

.breadcrumb-wama {
  display: inline-block;
  position: relative;
  top: 25px; }

.view-mode {
  margin-right: 10px;
  margin-top: 30px;
  margin-bottom: 10px; }

.div-inline {
  display: inline-block; }

.fa-rotate-270 {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg); }

#stockLevel .tooltip {
  min-width: 150px; }

.angular-google-map-container {
  height: 300px;
  border-radius: 5px; }

.product-select-item {
  width: 100%;
  margin-bottom: 5px; }
  .product-select-item img {
    float: left;
    margin: 0 10px 0 0px;
    height: 40px;
    width: 40px;
    border-radius: 10%;
    display: inline-block; }
  .product-select-item a {
    width: 100%;
    text-align: left; }
  .product-select-item .product-info {
    display: inline-block; }
  .product-select-item .barcode {
    font-weight: 600;
    font-size: 9pt;
    color: #555555;
    margin-top: 5px; }
  .product-select-item .quantity {
    display: inline-block;
    margin-top: 10px;
    font-weight: bold;
    float: right;
    font-size: 13pt;
    color: #898989; }

.barcode-format {
  display: inline-block;
  margin-right: 20px; }
  .barcode-format .image-format {
    text-align: center;
    margin: auto; }

.barcode-format-custom {
  display: table;
  background: #f3f3f3;
  border-radius: 5px;
  margin: 5px;
  padding: 10px; }
  .barcode-format-custom input {
    width: 300px;
    margin-bottom: 5px;
    padding: 5px; }

.chip {
  display: flex;
  padding: 0 25px;
  height: 50px;
  font-size: 16px;
  line-height: 50px;
  border-radius: 25px;
  background-color: #f1f1f1;
  margin-right: 10px; }
  .chip img {
    float: left;
    margin: 0 10px 0 -25px;
    height: 50px;
    width: 50px;
    border-radius: 50%; }
  .chip .text {
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.noneSelected {
  font-size: 1.5em;
  color: #189c25;
  margin-top: 20px;
  text-align: center; }

.closebtn {
  padding-left: 10px;
  color: #888;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer; }

.closebtn:hover {
  color: #000; }

.barcode-pagination-btns {
  float: right;
  margin-top: 20px; }

.barcode-categories-container {
  height: 625px; }

.btn-feedback {
  position: fixed;
  top: 50%;
  right: 0;
  border-radius: 0px 0px 4px 4px;
  text-transform: uppercase;
  box-shadow: none;
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -ms-transform-origin: right top;
  -webkit-transform-origin: right top;
  -moz-transform-origin: right top;
  transform-origin: right top 0;
  background-color: #eff5fa;
  border-color: #68c264;
  border-width: 0 1px 1px 1px;
  color: #68c264;
  -webkit-transition: all 0.5s ease;
  /* Safari */
  transition: all 0.5s ease;
  opacity: 1;
  z-index: 1001; }

.btn-feedback .glyphicon {
  padding-left: 10px; }

.btn-feedback:hover,
.btn-feedback:active,
.btn-feedback:focus {
  padding-top: 15px;
  background-color: #eff5fa;
  border-color: #68c264;
  color: #68c264; }

.radio-settings {
  min-height: 60px;
  margin: 0px;
  padding-top: 15px; }

.bg-row-dark-grey {
  background-color: #eeeeee; }

.text-bold {
  font-weight: bold; }

.clear {
  clear: both; }

.centered {
  margin: 0 auto; }

.categoryImage {
  position: relative;
  width: 170px;
  height: 170px;
  margin: 0 auto;
  text-align: center; }

.faIcon {
  color: #999999;
  transition: box-shadow 0.3s ease-in-out; }

.faIcon:hover {
  color: #333333; }

.paletteBoard {
  position: absolute;
  top: 40px;
  right: 90px;
  background-color: #ffffff;
  z-index: 10;
  max-width: 215px;
  border: 1px solid #dddddd;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px; }

.paletteBoardClose {
  position: absolute;
  width: 25px;
  height: 25px;
  text-align: center;
  right: -8px;
  top: -8px;
  font-size: 14pt;
  border-radius: 50%;
  color: #888888;
  display: inline-block;
  background-color: #ffffff;
  border: 2px solid #dddddd; }

.paletteBoardClose .close-palette {
  position: absolute;
  left: 6px;
  top: -2px;
  color: #bbbbbb; }

.circleBase {
  display: inline-block;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  transition: box-shadow 0.3s ease-in-out;
  margin: 5px; }

.circleBase:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer; }

.circleBase:focus {
  outline: none;
  border: 0; }

.circleCategorySmall {
  display: inline-block;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  transition: box-shadow 0.3s ease-in-out;
  margin: 5px; }

.category-image {
  max-height: 100%; }

.categoryQuantity {
  font-size: 14pt;
  color: #333333;
  border: 1px solid #cccccc;
  border-radius: 10%;
  padding: 5px;
  background-color: #efefef;
  min-width: 50px;
  display: inline-block;
  text-align: center; }

.cursorPointer {
  cursor: pointer; }

.calculatePriceBox {
  background: #dfefe7;
  padding: 10px;
  margin-top: 15px;
  border: 1px solid #b5c1ba;
  overflow: hidden;
  border-radius: 5px; }

select.wamaSelect {
  border-radius: 4px;
  margin: 10px 35px 10px 10px;
  width: 300px;
  font-weight: bold;
  padding: 5px 35px 5px 5px;
  font-size: 16px;
  border: 1px solid #EEE;
  height: 34px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/favicon.ico") 98%/8% no-repeat #ffffff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.084); }

/* CAUTION: IE hackery ahead */
select.wamaSelect::-ms-expand {
  display: none;
  /* remove default arrow on ie10 and ie11 */ }

select.default {
  -webkit-appearance: none;
  background-color: white;
  box-shadow: rgba(102, 113, 123, 0.12) 0px 1px 2px 0px inset;
  color: #212933;
  max-height: 42px;
  position: relative;
  width: 100%;
  z-index: 20;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  line-height: 24px;
  border-width: 1px;
  border-style: solid;
  border-color: #d8dde1;
  border-radius: 4px;
  padding: 8px 32px 8px 12px; }

@media (min-width: 768px) {
  .wama-select {
    width: 20%; } }

@media (max-width: 480px) {
  .wama-select {
    width: 100%; } }

.wama-select {
  box-sizing: border-box;
  float: left;
  left: 0;
  position: relative; }
  .wama-select img {
    fill: #5c656f;
    display: block;
    z-index: 40;
    pointer-events: none;
    position: absolute;
    height: 16px;
    width: 16px;
    top: 1px;
    right: 1px;
    margin: 12px; }

.btn-no-brackground {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  border: 0;
  background: transparent;
  color: #555555; }

.pagination-with-dropdown {
  margin-top: 20px;
  display: flex; }
  .pagination-with-dropdown .pagination-selector {
    text-align: center;
    flex-grow: 100; }

.pagination-container {
  margin-top: 20px;
  text-align: center; }

.table-hover tr {
  height: 50px;
  min-height: 50px;
  line-height: 50px; }

/* target Internet Explorer 9 to undo the custom arrow */
@media screen and (min-width: 0 \0 ) {
  select.wamaSelect {
    background: none \9;
    padding: 5px \9; } }

.new-item-box {
  border: 1px solid #cccccc;
  padding: 10px;
  margin-top: 5px;
  background-color: #f7f7f7;
  position: relative; }
  .new-item-box .close {
    top: 5px;
    right: 10px; }

.subscriptionInfo .box-header {
  color: #b9b9b9;
  text-transform: uppercase; }

.subscriptionInfo .progress-wrapper {
  margin: 20px 10px;
  font-size: 15px;
  width: 140px; }

.subscriptionInfo .current {
  position: absolute;
  color: #b9b9b9;
  font-weight: 100;
  line-height: 1; }

.subscriptionInfo .caption-progress {
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #b9b9b9; }

div #round-progress {
  --low-color: rgba(11, 255, 0, 0.6);
  --middle-color: rgba(254, 255, 0, 0.6);
  --high-color: rgba(255, 0, 36, 0.6); }

div .plan md-card-content {
  margin-left: 5px; }

span .little-text {
  display: inline;
  font-size: 0.5em; }

.plan-card {
  margin-top: 5px;
  border-radius: 8px;
  max-width: 300px;
  min-width: 250px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2); }

.plan-card:hover {
  margin-top: 0px;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
  transition-timing-function: ease-out;
  transition-duration: .15s;
  transform: translateY(0); }

.card-action-button {
  margin-bottom: 18px; }

.plan-buy-button {
  background-color: #fff;
  color: #0299e0;
  border-radius: 12px;
  font-weight: bold;
  width: 200px;
  height: 40px;
  border: 1px solid #0299e0; }

.plan-buy-button:hover {
  border: 0;
  color: #fff;
  background-color: #00b3ee;
  transition-timing-function: ease-out;
  transition-duration: .15s; }

.plan-buy-button.unsubscribe {
  color: #f71752;
  border: 1px solid #f71752; }

.plan-buy-button.unsubscribe:hover {
  color: #fff;
  background-color: #f71752; }

.plan-buy-button.disabled {
  background-color: #bbbbbb;
  color: #fff;
  visibility: hidden;
  border-color: transparent; }

.plan-current-button {
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  background-color: #00cc66;
  text-align: center;
  color: #fff;
  width: 200px;
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                      supported by Chrome and Opera */ }

.plan-current-button:hover {
  cursor: default; }

.progressbar-center {
  display: inline-block; }

.floating-menu {
  position: absolute;
  right: -10px;
  margin-top: 2px;
  z-index: 200;
  width: auto;
  background-color: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 5px 15px; }

.filter-enabled {
  color: #0299E0; }

.closed {
  color: #631716; }

.attr-container {
  display: flex;
  /* equal height of the children */
  justify-items: flex-start;
  align-items: center; }

.attr-col {
  padding: 1em; }

.attr-title {
  display: block;
  color: #70757a;
  font-size: 10pt; }

.attr-value {
  display: block;
  font-size: 13pt; }

.text-subtitle {
  color: #aaaaaa;
  font-size: 10pt; }

.btn-nested-input {
  float: right;
  position: relative;
  top: -27px;
  right: 10px; }

.module {
  display: inline-block;
  list-style: none;
  background: #ffffff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
  overflow: hidden;
  border-radius: 10px;
  padding: 10px;
  width: 280px;
  margin-right: 10px;
  margin-bottom: 10px; }

.module .name {
  font-size: 16px;
  line-height: 22px;
  margin-top: 20px;
  font-weight: 600;
  color: #202124;
  text-transform: uppercase; }

.module .description-group {
  font-size: 11px;
  color: #999999; }

.module .description {
  margin-bottom: 5px; }

.module img {
  max-width: 80px;
  max-height: 80px;
  margin-right: 10px; }

.image-description {
  display: flex;
  align-items: center; }

.module md-switch {
  margin-bottom: 0; }

.module-settings {
  margin-top: 16px; }
  .module-settings a:hover {
    text-decoration: none; }

.settings-select-photo-btn-container {
  display: block; }
  .settings-select-photo-btn-container .settings-select-photo-btn {
    margin-top: 10px; }

.module-logo {
  font-size: 45pt;
  padding: 10px;
  color: #cccccc; }

.module .description-link {
  margin-right: 20px; }

.grid {
  position: absolute;
  list-style: none;
  background: #ffffff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
  overflow: hidden;
  border-radius: 10px; }

.grid-img {
  width: 100%;
  vertical-align: middle;
  -webkit-transition: opacity 0.6s ease-out;
  transition: opacity 0.6s ease-out;
  background-color: #fff;
  opacity: 0;
  visibility: hidden; }

.grid-img.img-loaded {
  visibility: visible;
  opacity: 1; }

.grid-wrap {
  padding: 20px 85px 85px 85px;
  color: #333333; }

.dynamic-grid {
  position: relative;
  display: none; }

.dynamic-grid.angular-grid {
  display: block; }

.grid.ng-leave {
  -webkit-transition: all ease 400ms;
  transition: all ease 400ms; }

.grid.ng-leave.ng-leave-active {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  opacity: 0; }

.grid.ng-enter {
  -webkit-transition: all ease 400ms;
  transition: all ease 400ms;
  -webkit-transition-delay: 500ms;
  transition-delay: 500ms;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  opacity: 0; }

.grid.ng-enter.ng-enter-active {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1; }

.grid-img.img-loaded {
  visibility: visible;
  opacity: 1; }

.loading-more-indicator {
  text-align: center; }

.top-bar {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px;
  flex-wrap: wrap; }
  .top-bar .shop-logo {
    max-height: 55px;
    margin-right: 10px; }
  .top-bar .title {
    color: #888888;
    font-size: 20pt; }

.img-desc-wrap {
  vertical-align: bottom;
  padding: 10px; }

.img-desc-wrap .title {
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  color: #5f6368; }

.img-desc-wrap .price {
  font-weight: bold;
  color: #212121;
  font-family: 'GoogleSans-Local-Bold', 'Roboto-Bold', 'HelveticaNeue-Bold', HelveticaNeue, Sans-Serif-Bold, Arial, Sans-Serif;
  font-size: 18px; }

.img-desc-wrap .code {
  margin-top: 5px;
  font-size: 11px;
  color: #5f6368;
  font-weight: normal; }

@media (min-width: 768px) {
  #shop_products-wrap {
    padding: 10px 15px; } }

#shop_products-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  align-items: stretch; }
  @media (min-width: 768px) {
    #shop_products-wrap {
      justify-content: flex-start; } }
  #shop_products-wrap .shop_product {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    background-color: white;
    border-radius: 8px;
    box-sizing: border-box;
    overflow: hidden;
    margin: 6px;
    padding: 16px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16); }
  #shop_products-wrap .item {
    max-width: 300px;
    flex: 1 1 240px; }
    @media (min-width: 768px) {
      #shop_products-wrap .item {
        max-width: 300px;
        flex: 1 1 240px; } }
  #shop_products-wrap .shop_product-img-container {
    width: 100%;
    text-align: center; }
    #shop_products-wrap .shop_product-img-container .shop_product-img {
      max-height: 238px;
      width: 100%; }

@media screen and (max-width: 850px) {
  #shop_product-details {
    flex-direction: column; } }

@media screen and (min-width: 850px) {
  .img-container {
    margin-right: 10px; }
  .detais {
    margin-right: 20px; } }

#shop_product-details {
  display: flex;
  min-height: 600px;
  padding: 20px; }
  #shop_product-details .img-container {
    margin-top: 10px;
    flex: 1 0 40%; }
    #shop_product-details .img-container img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      min-width: 300px;
      border-radius: 5px;
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
      max-height: 550px; }
  #shop_product-details .details {
    margin-top: 10px;
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
    flex: 1 1 auto; }

.shop-no_products {
  font-size: 2em;
  color: #444444;
  margin: 10px; }

#shop-category-opener {
  margin-top: 28px;
  margin-left: 30px;
  margin-bottom: 20px;
  color: #555555;
  outline: none; }
  @media (min-width: 768px) {
    #shop-category-opener {
      display: none; } }

#shop-search-button {
  margin-top: 28px;
  margin-right: 30px;
  margin-bottom: 20px;
  color: #555555;
  outline: none; }

#shop-search-form {
  display: inline-block;
  padding-left: 20%;
  padding-right: 20%;
  margin-top: 20px;
  margin-bottom: 20px; }

#shop-top-bar {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse; }

#shop_categories-container {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  width: 200px;
  margin-bottom: 40px; }
  @media (max-width: 768px) {
    #shop_categories-container {
      display: none; } }

.shop-category {
  font-size: 1.3em;
  padding: 10px;
  margin-left: 10px;
  color: #333333;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  max-height: 65px;
  margin-bottom: 5px;
  margin-top: 5px;
  outline: none; }

.shop-category:hover {
  background-color: #0299E0;
  font-weight: bold;
  cursor: pointer;
  color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
  margin-bottom: 5px; }

.shop-category-active {
  display: flex;
  justify-content: space-between;
  background-color: #0299E0;
  font-weight: bold;
  cursor: pointer;
  color: #ffffff;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 65px;
  border-radius: 8px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
  -webkit-line-clamp: 2; }

.shop-category_close {
  vertical-align: middle;
  line-height: 25px !important; }

.shop_categories-container_mobile {
  position: fixed;
  left: 0;
  top: 0;
  width: 320px;
  height: 100%;
  z-index: 9999999;
  background-color: #ffffff;
  font-size: 1.2em;
  color: #333333;
  overflow: hidden; }
  @media (min-width: 768px) {
    .shop_categories-container_mobile {
      display: none; } }

.shop-category-mobile {
  display: flex;
  justify-content: space-between;
  height: 50px;
  line-height: 50px;
  vertical-align: middle;
  padding-left: 20px;
  -webkit-line-clamp: 1; }

.shop-category-mobile_text {
  text-overflow: ellipsis;
  overflow: hidden; }

.shop-category-mobile-row {
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 1px solid #eeeeee; }

.shop_categories-mobile_menu {
  display: flex;
  justify-content: space-between;
  background-color: #3c3c3c;
  color: white;
  height: 50px;
  line-height: 50px;
  vertical-align: middle;
  padding-left: 20px; }

.shop_categories-mobile_close {
  color: #ffffff;
  margin-right: 20px; }

.shop-category-mobile_badge {
  text-align: center;
  font-size: 1.4em;
  margin-bottom: 10px;
  color: #ffffff; }
  @media (min-width: 768px) {
    .shop-category-mobile_badge {
      display: none; } }

.shop-search_badge {
  text-align: center;
  font-size: 1.4em;
  margin-bottom: 10px;
  color: #ffffff; }

.shop-wrap {
  min-height: 100%; }

#shop-main {
  overflow: auto;
  padding-bottom: 50px; }

.shop-footer {
  background: #ececec;
  padding: 20px;
  color: #999;
  font-size: 12px;
  position: relative;
  margin-top: -50px;
  /* negative value of footer height */
  height: 100px;
  clear: both; }

.new-notification {
  color: #0299E0;
  font-size: 7pt;
  text-shadow: 1px 1px 3px #aaa; }

.table-responsive {
  box-shadow: rgba(12, 15, 20, 0.02) 0px 0px 0px 1px, rgba(12, 15, 20, 0.06) 0px 0px 1px 0px, rgba(12, 15, 20, 0.06) 0px 2px 2px 0px;
  background-color: #ffffff; }

.loyalty-card {
  display: flex;
  flex-direction: row;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 15px;
  background-color: #e9e9e9;
  align-items: center; }

.integration .spotify-activation {
  border: 1px solid #CCC;
  border-radius: 5px;
  background-color: #fff;
  max-width: 450px;
  padding: 30px;
  margin: auto; }

.shopify-shop-line {
  background-color: #FFFFFF;
  padding: 10px;
  color: #555555;
  margin-bottom: 10px;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.3);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px; }

.shopify-location {
  margin-bottom: 15px;
  border: 1px solid #dddddd;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #FFFFFF; }
;
.checkbox-container {
  float: left;
  width: 50%;
  box-sizing: border-box;
  text-align: center;
  padding: 40px 0px; }

.input-title {
  clear: both;
  font-size: 16px;
  color: rgba(51, 51, 51, 0.6);
  font-weight: 300; }

/* Styling Checkbox Starts */
.checkbox-label {
  display: block;
  position: relative;
  margin: auto;
  cursor: pointer;
  font-size: 22px;
  line-height: 24px;
  height: 24px;
  clear: both; }

.checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer; }

.checkbox-label .checkbox-custom {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 24px;
  width: 24px;
  background-color: transparent;
  border-radius: 5px;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  border: 2px solid #CCCCCC; }

.checkbox-label input:checked ~ .checkbox-custom {
  background-color: #CCCCCC;
  border-radius: 5px;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  opacity: 1;
  border: 2px solid #CCCCCC; }

.checkbox-label .checkbox-custom::after {
  position: absolute;
  content: "";
  left: 12px;
  top: 12px;
  height: 0px;
  width: 0px;
  border-radius: 5px;
  border: solid #009BFF;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(0deg) scale(0);
  -ms-transform: rotate(0deg) scale(0);
  transform: rotate(0deg) scale(0);
  opacity: 1;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out; }

.checkbox-label input:checked ~ .checkbox-custom::after {
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  opacity: 1;
  left: 8px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid #009BFF;
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0; }

/* For Ripple Effect */
.checkbox-label .checkbox-custom::before {
  position: absolute;
  content: "";
  left: 10px;
  top: 10px;
  width: 0px;
  height: 0px;
  border-radius: 5px;
  border: 2px solid #CCCCCC;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0); }

.checkbox-label input:checked ~ .checkbox-custom::before {
  left: -3px;
  top: -3px;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  -webkit-transform: scale(3);
  -ms-transform: scale(3);
  transform: scale(3);
  opacity: 0;
  z-index: 999;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out; }

/* Style for Circular Checkbox */
.checkbox-label .checkbox-custom.circular {
  border-radius: 50%;
  border: 2px solid #CCCCCC; }

.checkbox-label input:checked ~ .checkbox-custom.circular {
  background-color: #CCCCCC;
  border-radius: 50%;
  border: 2px solid #CCCCCC; }

.checkbox-label input:checked ~ .checkbox-custom.circular::after {
  border: solid #0067FF;
  border-width: 0 2px 2px 0; }

.checkbox-label .checkbox-custom.circular::after {
  border-radius: 50%; }

.checkbox-label .checkbox-custom.circular::before {
  border-radius: 50%;
  border: 2px solid #CCCCCC; }

.checkbox-label input:checked ~ .checkbox-custom.circular::before {
  border-radius: 50%; }
;
.stripe-body {
  /* Overall Container */
  /* Checkout */
  /* Form */
  /* Payment Methods */
  /* Media Queries */ }
  .stripe-body * {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    font-size: 15px;
    line-height: 1.4em;
    color: #525f7f; }
  .stripe-body #stripe-main {
    width: 100%;
    text-align: center;
    transition: width 0.3s ease-in-out; }
  .stripe-body #stripe-main.checkout:not(.success):not(.error) {
    width: calc(100% - 450px); }
  .stripe-body #checkout {
    max-width: 480px;
    margin: 0 auto;
    padding: 30px 0;
    transition: visibility 0s, opacity 0.5s linear 0.5s; }
  .stripe-body #stripe-main.checkout #checkout {
    visibility: visible;
    opacity: 1; }
  .stripe-body section {
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: left; }
  .stripe-body h1 {
    margin: 0 0 20px 0;
    font-size: 20px;
    font-weight: 500; }
  .stripe-body h2 {
    margin: 15px 0;
    color: #32325d;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    font-size: 13px;
    font-weight: 500; }
  .stripe-body .stripe-fieldset {
    margin-bottom: 20px;
    background: #fff;
    box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.15), 0 4px 6px 0 rgba(112, 157, 199, 0.15);
    border-radius: 4px;
    border: none;
    font-size: 0; }
  .stripe-body .stripe-fieldset .stripe-label {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 42px;
    padding: 10px 0;
    align-items: center;
    justify-content: center;
    color: #8898aa;
    font-weight: 400; }
  .stripe-body .stripe-fieldset .stripe-label:not(:last-child) {
    border-bottom: 1px solid #f0f5fa; }
  .stripe-body .stripe-fieldset .stripe-label.state {
    display: inline-flex;
    width: 75%; }
  .stripe-body .stripe-fieldset:not(.with-state) .stripe-label.state {
    display: none; }
  .stripe-body .stripe-fieldset .stripe-label.zip {
    display: inline-flex;
    width: 25%;
    padding-right: 60px; }
  .stripe-body .stripe-fieldset:not(.with-state) .stripe-label.zip {
    width: 100%; }
  .stripe-body .stripe-fieldset .stripe-label span {
    min-width: 125px;
    padding: 0 15px;
    text-align: right; }
  .stripe-body .stripe-fieldset .redirect .stripe-label span {
    width: 100%;
    text-align: center; }
  .stripe-body p.instruction {
    display: inline-table;
    margin-top: -32px;
    padding: 0 5px;
    text-align: center;
    background: #f8fbfd; }
  .stripe-body span#generate {
    cursor: pointer;
    color: #525f7f;
    text-decoration: underline; }
  .stripe-body span#generate:hover {
    text-decoration: none; }
  .stripe-body .field {
    flex: 1;
    padding: 0 15px;
    background: transparent;
    font-weight: 400;
    color: #31325f;
    outline: none;
    cursor: text; }
  .stripe-body .field::-webkit-input-placeholder {
    color: #aab7c4; }
  .stripe-body .field::-moz-placeholder {
    color: #aab7c4; }
  .stripe-body .field:-ms-input-placeholder {
    color: #aab7c4; }
  .stripe-body .stripe-fieldset .select::after {
    content: '';
    position: absolute;
    width: 9px;
    height: 5px;
    right: 20px;
    top: 50%;
    margin-top: -2px;
    background-image: url(/content/images/dropdown.svg);
    pointer-events: none; }
  .stripe-body input {
    flex: 1;
    border-style: none;
    outline: none;
    color: #313b3f; }
  .stripe-body select {
    flex: 1;
    border-style: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    color: #313b3f;
    cursor: pointer;
    background: transparent; }
  .stripe-body select:focus {
    color: #0277ae; }
  .stripe-body ::-webkit-input-placeholder {
    color: #cfd7e0; }
  .stripe-body ::-moz-placeholder {
    color: #cfd7e0;
    opacity: 1; }
  .stripe-body :-ms-input-placeholder {
    color: #cfd7e0; }
  .stripe-body input:-webkit-autofill,
  .stripe-body select:-webkit-autofill {
    -webkit-text-fill-color: #666ee8;
    transition: background-color 100000000s;
    -webkit-animation: 1ms void-animation-out 1s; }
  .stripe-body .StripeElement--webkit-autofill {
    background: transparent !important; }
  .stripe-body #card-element {
    margin-top: -1px; }
  .stripe-body #ideal-bank-element {
    padding: 0; }
  .stripe-body button {
    display: block;
    background: #0299E0;
    color: #fff;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    border: 0;
    font-weight: 700;
    width: 100%;
    height: 40px;
    outline: none;
    cursor: pointer;
    transition: all 0.15s ease; }
  .stripe-body button:focus {
    background: #0277ae; }
  .stripe-body button:hover {
    transform: translateY(-1px);
    box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.08); }
  .stripe-body button:active {
    background: #02618d; }
  .stripe-body #country {
    display: flex;
    align-items: center; }
  .stripe-body #country select {
    margin: 0 -15px 0 -30px;
    padding: 0 15px 0 30px; }
  .stripe-body .payment-info:not(.card) {
    margin-bottom: 15px; }
  .stripe-body .payment-info.ideal {
    margin-bottom: 0; }
  .stripe-body #payment-methods.visible,
  .stripe-body .payment-info.visible {
    display: block; }
  .stripe-body .payment-info.card.visible,
  .stripe-body .payment-info.sepa_debit.visible {
    text-align: center; }
  .stripe-body .payment-info p.notice {
    font-size: 14px;
    color: #8898aa;
    text-align: left; }
  .stripe-body #wechat-qrcode img {
    margin: 0 auto; }
  .stripe-body .element-errors {
    display: inline-flex;
    height: 20px;
    margin: 15px auto 0;
    padding-left: 20px;
    color: #e25950;
    opacity: 0;
    transform: translateY(10px);
    transition-property: opacity, transform;
    transition-duration: 0.35s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    background: url(/content/images/error.svg) center left no-repeat;
    background-size: 15px 15px; }
  .stripe-body .element-errors.visible {
    opacity: 1;
    transform: none; }
  @media only screen and (max-width: 1024px) {
    .stripe-body #stripe-main.checkout:not(.success):not(.error) {
      width: calc(100% - 320px); }
    .stripe-body #summary {
      width: 320px;
      right: -320px; }
    .stripe-body #stripe-main.checkout:not(.success):not(.error) + #summary {
      right: 0; }
    .stripe-body #summary .line-item p {
      margin-top: 0; }
    .stripe-body #summary .line-item .image {
      width: 40px;
      height: 40px; }
    .stripe-body #summary .line-item .label {
      margin: 0; } }
  @media only screen and (max-width: 800px) {
    .stripe-body #stripe-main.checkout:not(.success):not(.error) {
      width: 100%; }
    .stripe-body #payment-request {
      padding-top: 0;
      min-height: 80px; }
    .stripe-body #summary {
      display: none; }
    .stripe-body #confirmation .status {
      width: auto;
      height: auto;
      margin: 30px; } }
  @media only screen and (max-width: 500px) {
    .stripe-body .header {
      height: 60px;
      background-size: 40px 40px; }
    .stripe-body .header a.shop,
    .stripe-body .header a.github {
      display: none; }
    .stripe-body #payment-request {
      min-height: 0;
      padding-right: 15px;
      padding-left: 15px; }
    .stripe-body #payment-form {
      margin: 0;
      padding: 0 15px;
      border-width: 2px 0 0 0;
      border-radius: 0; }
    .stripe-body .payment-info span {
      display: none; }
    .stripe-body .stripe-fieldset {
      margin-bottom: 15px; }
    .stripe-body .stripe-fieldset .stripe-label.state,
    .stripe-body .stripe-fieldset .stripe-label.zip {
      display: flex;
      width: inherit;
      padding: 10px 0; }
    .stripe-body p.instruction {
      margin-top: -12px;
      font-size: 14px; }
    .stripe-body p.tip {
      margin-bottom: 0;
      font-size: 13px; }
    .stripe-body #country::before {
      display: none; }
    .stripe-body #checkout {
      margin-bottom: 0; } }
;